<template>
  <v-container class="mt-16">
    <div class="pl pr">
      <v-card flat>
        <v-card-title class="d-flex">
          <h2>Quotations</h2>
          <v-spacer></v-spacer>
          <add-quotation-modal />
        </v-card-title>
        <v-card-text>
          <v-text-field
            prepend-icon="mdi-magnify"
            label="Search"
            v-model="search"
          ></v-text-field>
          <v-data-table :search="search" :items="quotations" :headers="headers">
            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <view-quotation-modal :item="item" />
                <v-btn icon color="green" @click="print(item)"
                  ><v-icon>mdi-printer</v-icon></v-btn
                >
                <v-btn icon color="error" @click="del(item)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import addQuotationModal from "../components/Quotation/addQuotationModal.vue";
import ViewQuotationModal from "../components/Quotation/viewQuotationModal.vue";

export default {
  components: { addQuotationModal, ViewQuotationModal },
  data() {
    return {
      search: "",
      headers: [
        { text: "Actions", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Quote No.", value: "quote_number" },
        { text: "Customer Name", value: "customer_name" },
        { text: "Company Name", value: "company_name" },

        { text: "Notes", value: "notes" },
        { text: "Quotation Date", value: "quotation_date" },
        { text: "Expiry Date", value: "quote_expiry_date" },
        { text: "Total", value: "total" },
      ],
    };
  },
  methods: {
    ...mapActions({
      get_quotations: "quotation/get_quotations",
      delete_quotation: "quotation/delete_quotation",
    }),

    print(item) {
      let url = this.default_server_url + "/print_quotation/" + item.id;
      window.open(url);
    },
    del(item) {
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        this.delete_quotation(item).then(() => {
          alert("Successfully deleted");
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      quotations: "quotation/quotations",
      default_server_url: "auth/default_server_url",
    }),
  },
  created() {
    if (this.quotations.length == 0) this.get_quotations();
  },
};
</script>

<style></style>
