<template>
  <div>
    <v-btn @click="dialog = true">Add Quotation</v-btn>
    <v-dialog v-model="dialog" persistent width="1300">
      <v-card>
        <v-card-title>Add Quotation</v-card-title>
        <v-card-text>
          <div class="d-flex">
            <v-text-field
              label="Name"
              prepend-icon="mdi-account"
              v-model="form.name"
            ></v-text-field>
            <v-text-field
              label="Company Name"
              prepend-icon="mdi-domain"
              v-model="form.company_name"
            ></v-text-field>
            <v-text-field
              label="Address"
              prepend-icon="mdi-map-marker"
              v-model="form.address"
            ></v-text-field>
            <v-text-field
              label="Phone Number"
              prepend-icon="mdi-phone"
              v-model="form.phone_number"
            ></v-text-field>
          </div>
          <v-row>
            <v-col cols="6">
              <v-textarea
                label="Notes"
                height="100"
                aria-label="Notes"
                v-model="form.notes"
                prepend-icon="mdi-draw"
                placeholder="Eg. Purchase of 1 Desktop Computer for Editing Purposes"
              ></v-textarea>
              <v-radio-group v-model="quote_type" row label="Quotation Type">
                <v-radio label="Mixed" value="mixed"></v-radio>
                <v-radio label="Desktop PC" value="desktop"></v-radio>
                <v-radio label="CCTV" value="cctv"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-text-field
                label="Quotation Date"
                prepend-icon="mdi-calendar"
                v-model="form.quotation_date"
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Quotation Expiration Date"
                prepend-icon="mdi-calendar"
                v-model="form.quotation_expiry_date"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <desktop-quotation-table
            :tax_rate="tax_rate"
            :shipping_fee="form.shipping_fee"
            :margin_percentage="form.margin_percentage"
            @totalForm="get_total_form"
            @reset="reset"
            @final_list_arr="get_final_list_arr"
          />

          <hr />
          <v-row class="justify-end mt-1 mr-5">
            <v-col class="mt-6">
              <div class="d-flex" v-if="this.user.access_level == 4">
                <h2>Total Capital: {{ totalForm.total_capital }}</h2>
                <v-spacer></v-spacer>
                <h2>
                  Total Profit:
                  {{ Math.floor(total_amount - totalForm.total_capital) }}
                </h2>
              </div>
              <v-textarea
                class="mt-7"
                height="100"
                aria-label="Remarks"
                v-model="form.remarks"
                prepend-icon="mdi-draw"
                label="Remarks"
                placeholder="Enter remarks here like delivery dates, tax inclusive/exclusiv, etc."
              ></v-textarea>
            </v-col>

            <v-col cols="3" class="mt-4" v-if="this.user.access_level == 4">
              <v-text-field
                :hidden="user.access_level == 4 ? false : true"
                label="Margin Percentage"
                prepend-icon="mdi-percent"
                v-model="form.margin_percentage"
              ></v-text-field>
              <v-text-field
                :hidden="user.access_level == 4 ? false : true"
                label="Shipping Fee"
                prepend-icon="mdi-percent"
                v-model="form.shipping_fee"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-radio-group
                v-model="tax_inc"
                row
                label="Tax Inc Option"
                @change="calculate_vat"
              >
                <v-radio label="Non-VAT" value="non-vat"></v-radio>
                <v-radio label="VAT" value="vat"></v-radio>
              </v-radio-group>
              <h3>Subtotal: {{ this.totalForm.subTotal }}</h3>
              <h3>VAT: {{ this.totalForm.vat_amount }}</h3>
              <div class="d-flex">
                <v-text-field
                  label="Discount"
                  v-model="totalForm.discount"
                ></v-text-field>
              </div>
              <h2 class="green--text">Total: {{ total_amount }}</h2>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">addQuotationModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import desktopQuotationTable from "./desktopQuotationTable.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { desktopQuotationTable },
  data() {
    return {
      tax_rate: 20,
      totalForm: {
        subTotal: 0,
        discount: 0,
        vat_amount: 0,
        total: 0,
        total_profit: 0,
      },
      quote_type: "desktop",
      tax_inc: "non-vat",
      dialog: false,
      loading: false,
      form: {
        remarks: "Warranty: 6 months Parts Replacement.",
        margin_percentage: 45,
        shipping_fee: 1000,
      },
    };
  },
  methods: {
    ...mapActions({
      add_quotation: "quotation/add_quotation",
    }),
    reset(value) {
      this.totalForm = value;
    },

    calculate_vat() {
      if (this.tax_inc == "vat") {
        var vatable = this.totalForm.subTotal;
        var vat = vatable * 0.12;
        this.totalForm.vat_amount = vat;
      } else {
        this.totalForm.vat_amount = 0;
      }
      this.get_total_form;
    },
    get_final_list_arr(item) {
      //   strip all rows with values item_name == "N/A"
      let filtered_arr = item.filter((i) => {
        return i.item_name != "N/A";
      });
      this.form.filtered_final_arr = filtered_arr;
    },
    get_total_form(item) {
      this.totalForm = item;
      this.assign_values(item);
    },

    assign_values(item) {
      /** assign to form
       * 1. Subtotal as sub_total
       * 2. Vat Amount as vat
       * 3. Discount as discount
       * 4. Total as total
       **/
      this.form.sub_total = item.subTotal;
      this.form.vat = item.vat_amount;
      this.form.discount = item.discount;
      this.form.total = this.total_amount;
      this.form.total_capital_amount = item.total_capital;

      console.log("this.form", this.form);
    },
    async submit() {
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        this.assign_values(this.totalForm);
        this.add_quotation(this.form);
        alert("Successfully added a new quotation");
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),

    total_amount() {
      return (
        this.totalForm.subTotal +
        this.totalForm.vat_amount -
        this.totalForm.discount
      );
    },
  },
  mounted() {
    var date = moment().format("Y-M-DD");
    this.form.quotation_date = date;
    this.form.quotation_expiry_date = moment().add(15, "d").format("Y-M-DD");
  },
};
</script>

<style lang="scss" scoped></style>
