<template>
  <div>
    <v-btn icon color="primary" @click="dialog = true"
      ><v-icon>mdi-list-box</v-icon></v-btn
    >

    <v-dialog v-model="dialog" persistent width="1100">
      <v-card>
        <v-card-title>
          <h2>
            <span class="red--text">JMB</span
            ><span class="blue--text">Computers</span>
          </h2>
          <v-spacer></v-spacer>
          <h3 class="display-1">
            Quote#: {{ item.quote_number }}
          </h3></v-card-title
        >
        <v-card-text class="mt-4">
          <div class="d-flex">
            <v-text-field
              readonly
              label="Name"
              prepend-icon="mdi-account"
              v-model="item.customer_name"
            ></v-text-field>
            <v-text-field
              readonly
              label="Company Name"
              prepend-icon="mdi-domain"
              v-model="item.company_name"
            ></v-text-field>
            <v-text-field
              readonly
              label="Address"
              prepend-icon="mdi-map-marker"
              v-model="item.address"
            ></v-text-field>
            <v-text-field
              readonly
              label="Phone Number"
              prepend-icon="mdi-phone"
              v-model="item.phone_number"
            ></v-text-field>
          </div>
          <v-row>
            <v-col cols="6">
              <v-textarea
                readonly
                label="Notes"
                height="100"
                aria-label="Notes"
                v-model="item.notes"
                prepend-icon="mdi-draw"
                placeholder="Eg. Purchase of 1 Desktop Computer for Editing Purposes"
              ></v-textarea>
            </v-col>
            <v-col>
              <v-text-field
                readonly
                label="Quotation Date"
                prepend-icon="mdi-calendar"
                v-model="item.quotation_date"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                readonly
                label="Quotation Expiration Date"
                prepend-icon="mdi-calendar"
                v-model="item.quote_expiry_date"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            dense
            :items="item.quote_items"
            :headers="final_list_headers"
            :items-per-page="30"
          ></v-data-table>
          <!-- <hr />
          <v-row class="justify-end mt-1 mr-5">
            <v-col v-if="this.user.access_level == 4" class="mt-6">
              <div class="d-flex">
                <h2>Total Capital: {{ item.total_capital }}</h2>
                <v-spacer></v-spacer>
                <h2>
                  Total Profit: {{ total_amount - totalitem.total_capital }}
                </h2>
              </div>
              <v-textarea
                class="mt-7"
                height="100"
                aria-label="Remarks"
                v-model="item.remarks"
                prepend-icon="mdi-draw"
                label="Remarks"
                placeholder="Enter remarks here like delivery dates, tax inclusive/exclusiv, etc."
              ></v-textarea>
            </v-col>

            <v-col cols="3" class="mt-4" v-if="this.user.access_level == 4">
              <v-text-field readonly
                label="Margin Percentage"
                prepend-icon="mdi-percent"
                v-model="item.margin_percentage"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-radio-group
                v-model="tax_inc"
                row
                label="Tax Inc Option"
                @change="calculate_vat"
              >
                <v-radio label="Non-VAT" value="non-vat"></v-radio>
                <v-radio label="VAT" value="vat"></v-radio>
              </v-radio-group>
              <h3>Subtotal: {{ this.item.subTotal }}</h3>
              <h3>VAT: {{ this.item.vat_amount }}</h3>
              <div class="d-flex">
                <v-text-field readonly
                  label="Discount"
                  v-model="totalitem.discount"
                ></v-text-field>
              </div>
              <h2 class="green--text">Total: {{ total_amount }}</h2>
            </v-col>
          </v-row> -->
          <hr />
          <v-row class="mt-4">
            <v-col>
              <div class="d-flex" v-if="user.access_level == 4">
                <h2 class="mr-5">
                  Total Capital: {{ item.total_capital_amount }}
                </h2>

                <h2>
                  Total Profit:
                  {{ Math.floor(item.total - item.total_capital_amount) }}
                </h2>
              </div>
              <v-textarea
                readonly
                class="mt-7"
                height="100"
                aria-label="Remarks"
                v-model="item.remarks"
                prepend-icon="mdi-draw"
                label="Remarks"
              ></v-textarea>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="4">
              <h3>Subtotal: {{ this.item.sub_total }}</h3>
              <h3>VAT: {{ this.item.vat }}</h3>
              <div class="d-flex">
                <h3>Discount: {{ this.item.discount }}</h3>
              </div>
              <hr class="mt-3 mb-3" />
              <h2 class="green--text">Total: {{ this.item.total }}</h2>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">viewQuotationModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      dialog: false,
      loading: false,
      final_list_headers: [
        { text: "id", value: "id" },
        { text: "Item Type", value: "item_type", width: "200" },
        { text: "Item Name", value: "item_name" },
        { text: "Quantity", value: "quantity", width: "120" },
        { text: "Total Amount", value: "total_amount", width: "150" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    submit() {
      return;
    },
  },
};
</script>

<style lang="scss" scoped></style>
