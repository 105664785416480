var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h2',[_vm._v("Desktop Specs Table")]),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"Item Type","items":_vm.item_types,"item-text":"item_type","item-value":"id"},on:{"change":function($event){return _vm.get_item_type()}},model:{value:(_vm.itemResultsId),callback:function ($$v) {_vm.itemResultsId=$$v},expression:"itemResultsId"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Search","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Selected","readonly":""},model:{value:(_vm.pre_selected_obj.item_name),callback:function ($$v) {_vm.$set(_vm.pre_selected_obj, "item_name", $$v)},expression:"pre_selected_obj.item_name"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-text-field',{attrs:{"type":"number","label":"Quantity"},on:{"input":_vm.calculate_total_price},model:{value:(_vm.pre_selected_obj.quantity),callback:function ($$v) {_vm.$set(_vm.pre_selected_obj, "quantity", $$v)},expression:"pre_selected_obj.quantity"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Total"},model:{value:(_vm.pre_selected_obj.total_price),callback:function ($$v) {_vm.$set(_vm.pre_selected_obj, "total_price", $$v)},expression:"pre_selected_obj.total_price"}})],1),_c('v-col',{staticClass:"d-flex mt-3",attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.insert_to_final_list_arr}},[_vm._v("Insert")]),_c('v-btn',{staticClass:"justify-end",attrs:{"color":"yellow"},on:{"click":_vm.reset}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(" Reset")],1)],1)],1),_c('v-data-table',{attrs:{"search":_vm.search,"items":_vm.itemResults,"headers":_vm.itemResultsHeaders,"dense":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.select_item(item)}}},[_vm._v("Select")])],1)]}}])}),_c('v-data-table',{attrs:{"items-per-page":30,"dense":"","items":_vm.final_list_arr,"headers":_vm.final_list_headers},scopedSlots:_vm._u([{key:"item.item_type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.id)+" - "+_vm._s(item.item_type))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"small":"","color":"red","icon":""},on:{"click":function($event){return _vm.delete_item(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}},{key:"item.unit_price",fn:function(ref){
var item = ref.item;
return [(_vm.user.access_level == 4)?_c('span',[_vm._v(_vm._s(item.unit_price))]):_c('span',[_vm._v("N/A")])]}},{key:"item.total_unit_price",fn:function(ref){
var item = ref.item;
return [(_vm.user.access_level == 4)?_c('span',[_vm._v(_vm._s(item.total_unit_price))]):_c('span',[_vm._v("N/A")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }