<template>
  <v-container>
    <h2>Desktop Specs Table</h2>
    <v-row class="mt-2">
      <v-col cols="2">
        <v-select
          label="Item Type"
          :items="item_types"
          item-text="item_type"
          item-value="id"
          @change="get_item_type()"
          v-model="itemResultsId"
        ></v-select
      ></v-col>
      <v-col cols="2">
        <v-text-field
          label="Search"
          v-model="search"
          prepend-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col
        ><v-text-field
          label="Selected"
          readonly
          v-model="pre_selected_obj.item_name"
        ></v-text-field
      ></v-col>
      <v-col cols="1"
        ><v-text-field
          type="number"
          label="Quantity"
          v-model="pre_selected_obj.quantity"
          @input="calculate_total_price"
        ></v-text-field
      ></v-col>
      <v-col cols="2"
        ><v-text-field label="Total" v-model="pre_selected_obj.total_price">
        </v-text-field
      ></v-col>
      <v-col cols="2" class="d-flex mt-3"
        ><v-btn color="primary" class="mr-2" @click="insert_to_final_list_arr"
          >Insert</v-btn
        >
        <v-btn color="yellow" class="justify-end" @click="reset"
          ><v-icon>mdi-refresh</v-icon> Reset</v-btn
        >
      </v-col>
    </v-row>
    <!-- RESULTS ARR -->
    <v-data-table
      :search="search"
      :items="itemResults"
      :headers="itemResultsHeaders"
      dense
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <v-btn small color="primary" @click="select_item(item)">Select</v-btn>
        </div>
      </template>
    </v-data-table>

    <!-- FINAL LIST -->

    <v-data-table
      :items-per-page="30"
      dense
      :items="final_list_arr"
      :headers="final_list_headers"
    >
      <template v-slot:item.item_type="{ item }">
        <span>{{ item.id }} - {{ item.item_type }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <v-btn small color="red" icon @click="delete_item(item)"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </div>
      </template>

      <template v-slot:item.unit_price="{ item }">
        <span v-if="user.access_level == 4">{{ item.unit_price }}</span>
        <span v-else>N/A</span>
      </template>
      <template v-slot:item.total_unit_price="{ item }">
        <span v-if="user.access_level == 4">{{ item.total_unit_price }}</span>
        <span v-else>N/A</span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: ["tax_rate", "margin_percentage", "shipping_fee"],
  data() {
    return {
      totalForm: {
        subTotal: 0,
        discount: 0,
        vat_amount: 0,
        total: 0,
        total_capital: 0,
      },
      final_list_headers: [
        { text: "Action", value: "action", width: "90" },
        { text: "Item Type", value: "item_type", width: "275" },
        { text: "Item Name", value: "item_name" },
        { text: "Unit Price", value: "unit_price" },

        { text: "Quantity", value: "quantity", width: "120" },
        { text: "Total Unit Price", value: "total_unit_price", width: "100" },
        { text: "Total Amount", value: "total_price", width: "100" },
      ],

      final_list_arr: [
        {
          id: 0,
          item_type: "CPU MOBO BUNDLE",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 1,
          item_type: "CPU/PROCIE",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 2,
          item_type: "MOBO",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 3,
          item_type: "RAM",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 4,
          item_type: "GPU",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 5,
          item_type: "SSD",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 6,
          item_type: "HDD",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 7,
          item_type: "PSU",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 8,
          item_type: "DESKTOP CASE",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 9,
          item_type: "DESKTOP CASE FANS",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 10,
          item_type: "CPU COOLERS",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 11,
          item_type: "MONITOR",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 12,
          item_type: "KEYBOARD",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 13,
          item_type: "MOUSE",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 14,
          item_type: "KEYBOARD AND MOUSE BUNDLE",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 15,
          item_type: "SPEAKER",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 16,
          item_type: "WIFI DONGLE",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 17,
          item_type: "MOUSE PAD",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 18,
          item_type: "AVR",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 19,
          item_type: "UPS",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 20,
          item_type: "HEADSET/HEADPHONES",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 21,
          item_type: "WEBCAMERA",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 22,
          item_type: "DESKTOP ACCESSORIES",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
        {
          id: 23,
          item_type: "1 YEAR EXTENDED SERVICE WARRANTY",
          item_name: "N/A",
          quantity: 0,
          unit_price: 0,
          total_unit_price: 0,
          total_price: 0,
          icon: "mdi-cpu-64-bit",
        },
      ],
      pre_selected_obj: {
        item_type: "",
        item_name: "",
        unit_price: "",
        quantity: 1,
        total_unit_price: "",
        total_price: "",
      },
      item_lists: [],
      search: "",
      itemResultsHeaders: [
        { text: "Action", value: "action" },
        { text: "ID", value: "id" },
        { text: "Item Name", value: "item_name" },
        { text: "Unit Price", value: "unit_price" },
        { text: "Stocks", value: "quantity" },
        { text: "Batch", value: "import_batch.batch_description" },
        { text: "Unit", value: "unit" },
        { text: "Branch", value: "branch.branch_name" },
      ],
      itemResultsId: "",
      item_types: [],
      itemResults: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    total_price() {
      let total_price =
        (this.pre_selected_obj.unit_price * this.margin_percentage) / 100;
      // this.pre_selected_obj.total_price = total_price;
      return total_price;
    },
  },
  methods: {
    reset() {
      const resetValues = (item) => ({
        ...item,
        item_name: "N/A",
        quantity: 0,
        unit_price: 0,
        total_unit_price: 0,
        total_price: 0,
      });

      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        this.final_list_arr = this.final_list_arr.map(resetValues);
        this.$emit("reset", {
          subTotal: 0,
          discount: 0,
          vat_amount: 0,
          total: 0,
          total_capital: 0,
        });
      }
    },
    calculate_sub_total() {
      let sumTotalPrice = 0;
      this.final_list_arr.forEach(({ total_price }) => {
        sumTotalPrice += total_price;
      });
      sumTotalPrice += this.shipping_fee;
      this.totalForm.subTotal = sumTotalPrice;
    },
    insert_to_final_list_arr() {
      var index = this.final_list_arr.findIndex(
        (a) => a.item_type == this.pre_selected_obj.item_type
      );

      // insert items to columns
      this.final_list_arr[index].item_name = this.pre_selected_obj.item_name;
      this.final_list_arr[index].quantity = this.pre_selected_obj.quantity;
      this.final_list_arr[index].unit_price = this.pre_selected_obj.unit_price;
      this.final_list_arr[index].total_price =
        this.pre_selected_obj.total_price;
      this.final_list_arr[index].total_unit_price =
        this.pre_selected_obj.unit_price * this.pre_selected_obj.quantity;
      // calculate formTotal
      this.calculate_sub_total();
      this.calculate_capital_and_profit();
      // emit to addQuotationModal
      this.$emit("totalForm", this.totalForm);
      // emit final arr list
      this.$emit("final_list_arr", this.final_list_arr);
    },
    // upload_total_form() {
    //   this.$emit("totalForm", this.totalForm);
    // },
    calculate_capital_and_profit() {
      var total_capital = 0;
      this.final_list_arr.forEach((f) => {
        total_capital += Number(f.total_unit_price);
      });
      // console.log(total_capital);
      this.totalForm.total_capital = total_capital;
    },
    async get_item_type() {
      let response = await axios.get(
        "/get_items_from_item_type/" + this.itemResultsId
      );
      //   console.log(response.data);
      this.itemResults = response.data;
      this.pre_selected_obj.item_type = this.get_item_type_by_id(
        this.itemResultsId
      );
      // console.log(this.pre_selected_obj);
    },
    get_item_type_by_id(item_type_id) {
      const item = this.item_types.find((item) => item.id === item_type_id);
      return item ? item.item_type : null;
    },
    calculate_total_price() {
      let unitPrice = Number(this.pre_selected_obj.unit_price);
      let quantity = this.pre_selected_obj.quantity;
      let marginMultiplier = this.margin_percentage / 100;
      let marginAmount = unitPrice * marginMultiplier;
      let sellingPrice = unitPrice + marginAmount;
      this.pre_selected_obj.total_price = sellingPrice * quantity;
    },
    delete_item(item) {
      let x = window.confirm("Are you sure you want to remove this?");
      if (x) {
        var index = this.final_list_arr.findIndex((a) => a.id == item.id);

        this.final_list_arr[index].item_name = "N/A";
        this.final_list_arr[index].quantity = 0;
        this.final_list_arr[index].unit_price = 0;
        this.final_list_arr[index].total_unit_price = 0;
        this.final_list_arr[index].total_price = 0;

        // calculate formTotal
        this.calculate_sub_total();
        this.calculate_capital_and_profit();
        // emit to addQuotationModal
        this.$emit("totalForm", this.totalForm);
        // emit final arr list
        this.$emit("final_list_arr", this.final_list_arr);
      }
    },
    select_item(item) {
      this.pre_selected_obj.item_name = item.item_name;
      this.pre_selected_obj.unit_price = item.unit_price;

      this.calculate_total_price();
    },
  },
  async created() {
    let response = await axios.get("/item_types");
    this.item_types = response.data;
  },
};
</script>

<style></style>
